<template>
  <div class="u-position-relative campaign-details u-max-width-400px">
    <loader
      v-if="allEntititesTagsMap.load || load"
      class="fill--parent"
      :loading="true"
      :color="'#3684bb'"
    />
    <tagsAndSuggestions
      class="campaigns-tags-input filter--token"
      :enable-style-api="false"
      tags-suggestions-container-classes="campaigns-tags-input"
      :dropdown-data="getAllTags"
      :get-tags-and-suggestions-instance="getTagsAndSuggestionsInstance"
      :tags-aggregated-view="false"
      :tags-icons="[{ icon: 'cross', emit: 'removeActiveTag' }]"
      :delimiters="[{ token: ',' }, { token: '\n' }]"
      :initial-tags="initialTags"
      :is-input-disabled="false"
      input-placeholder="Add to campaign list"
      :has-tags-buffer="false"
      :hide-dropdown-at-select="true"
      :show-drop-down="true"
      :allow-multiple-tags="true"
      @textInput="handleTextInput"
      @handleEnter="handleEnter"
      @suggestionClick="suggestionClick"
      @removeActiveTag="removeActiveTag"
    >
      <div
        slot="dropdown-header"
        class="u-spacing-ph-m u-spacing-pv-m u-color-grey-lighter u-cursor-default u-font-size-7 u-spacing-pb-xs"
      >
        Add to campaign list
      </div>
      <div
        v-show="scope.textInput.length"
        slot="dropdown-footer"
        slot-scope="scope"
        class="u-spacing-ph-m u-spacing-pv-s u-cursor-pointer new-tags-selection"
        :style="scope.hoverIndex === -1 && scope.styleObject"
        @mouseenter="scope.handleMouseEnter(-1)"
        @mouseleave="scope.handleMouseLeave()"
        @click.stop="scope.handleSuggestionClick(scope.textInput)"
      >
        {{ scope.textInput }}
        <span class="u-color-grey-lighter">(Add as a new)</span>
      </div>
    </tagsAndSuggestions>
  </div>
</template>
<script>
// TODO --> Extract methods into a js file so that we can reuse this everywhere we have campaign tags
import loader from '@/components/basic/loader';
import tagsAndSuggestions from '@/components/widgets/tagsAndSuggestions';
import utils from '@/utils/helpers/';
import { cloneDeep } from 'lodash';
import { commons } from '@/components/ams/campaigns/commons.js';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import Vue from 'vue';
export default {
  components: {
    tagsAndSuggestions,
    loader
  },
  props: {
    entityId: {
      default: null,
      type: String
    },
    campaignName: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      tagsLoader: { load: false },
      tagsAndSuggestionsInstance: null,
      initialTags: [],
      tagState: {},
      load: false,
      APIConfig: {
        brandsRequest: {
          cubeName: Vue.options.filters.config_check('feature.dbxSetup.enable')
            ? 'ams_campaigns_campaign_workbench_client_catalog'
            : 'ams_campaigns_campaign_workbench',
          getLatestAvailableInsteadOfRollup: false,
          timeseriesEnabled: false,
          pvpenabled: false,
          yoyenabled: false,
          measuresList: [],
          groupByDimensionsList: ['campaign_name', 'campaign_id'],
          orderByList: [
            {
              dimension: 'campaign_name',
              direction: 'ASC'
            }
          ],
          where: {
            dimensionNameValueList: [
              {
                dimensionName: 'campaign_id',
                dimensionValue: ':entityId',
                operator: 'ILIKE'
              }
            ]
          }
        },
        tagType: 'custom',
        customType: 'Custom',
        entityType: 'campaign'
      }
    };
  },
  computed: {
    allEntititesTagsMap() {
      return this.$store.getters.getAllEntititesTagsMap;
    },
    getAllTags() {
      return this.$store.getters.getAllTags;
    }
  },
  watch: {
    allEntititesTagsMap: {
      handler(newVal) {
        if (!newVal.load && newVal.rows.length) {
          let id = 0;
          this.initialTags = newVal.rows[0].tags.map((item) => {
            id++;
            return {
              title: item.title,
              id
            };
          });
        }
      },
      deep: true
    }
  },
  created() {
    this.fetchExistingTags();
  },
  methods: {
    saveTagsAction() {
      this.handleRemoveTagActionTrigger();
      this.addTagsAction();
    },
    async handleRemoveTagActionTrigger() {
      if (!this.tagState?.remove?.length) return;
      this.load = true;
      for (let removeTag of this.tagState.remove) {
        await this.handleRemoveTagAction(removeTag);
      }
      this.load = false;
      this.fetchExistingTags();
      this.tagState.remove = [];
    },
    async handleRemoveTagAction(tag) {
      const data = {
        tag: {
          level1: tag,
          type: 'custom',
          customType: 'Custom'
        },
        entityDetail: {
          value: this.entityId,
          type: 'campaign'
        }
      };
      let message = '';
      try {
        await this.$store.dispatch('removeEntityFromATag', data);
        message = `Campaign is removed from Campaign list "${tag}" successfully`;
      } catch {
        message = 'Error, please try again';
      } finally {
        this.openSnackbar(message);
      }
    },
    openSnackbar(message) {
      const snackbarObject = {
        message: '',
        duration: 5000,
        actionText: ''
      };
      snackbarObject.message = message;
      this.$snackbar.open(snackbarObject);
    },
    removeActiveTag(item, tag) {
      if (!this.tagState?.remove) {
        this.tagState.remove = [];
      }
      this.tagState.remove.push(tag);
      this.emitTagsUpdated();
    },
    handleTextInput(data) {
      const payload = {
        entityType: 'campaign',
        tagType: 'custom',
        customType: 'Custom',
        prefix: encodeURIComponent(data.textInput)
      };
      this.fetchAllTags(payload);
    },
    suggestionClick(data) {
      let value = data?.title ? data.title : data;
      this.handleAddTagsForCampaigns([{ title: value }]);
    },
    handleEnter(data) {
      this.handleAddTagsForCampaigns([{ title: data }]);
    },
    fetchAllTags: commons.debounce(function (payload) {
      this.$store.dispatch('fetchAllTags', payload);
    }, 500),
    getTagsAndSuggestionsInstance(instance) {
      this.tagsAndSuggestionsInstance = instance;
    },
    handleAddTagsForCampaigns(activeTags) {
      const tagsToValidate = activeTags?.map((item) => {
        return { value: item.title, maxLength: 100 };
      });
      const validationCheck = commons.tagsValidation;
      const charCheck = utils.textValidation(validationCheck, tagsToValidate);
      if (charCheck) {
        const commonMessage = 'Campaign list name cannot ';
        const reasonMessage =
          charCheck.reason === 'character'
            ? 'have special characters'
            : `exceed ${charCheck.maxLength} characters`;
        const snackbarObject = {
          message: commonMessage + reasonMessage,
          duration: 5000,
          actionText: ''
        };
        this.$snackbar.open(snackbarObject);
        return;
      }
      if (!this.tagState?.add) {
        this.tagState.add = [];
      }
      this.tagState.add = [...this.tagState.add, ...activeTags];
      this.emitTagsUpdated();
    },
    emitTagsUpdated() {
      this.$emit('tagUpdated', this.tagState);
    },
    addTagsAction() {
      if (!this.tagState?.add?.length) return;
      this.tagsLoader.load = true;
      let error = false;
      let noNewEntityTag = false;
      const requestBody = this.createTagsRequest(this.tagState.add);
      const actionToCall = 'addTagToEntity';
      this.load = true;
      this.$store
        .dispatch(actionToCall, requestBody)
        .then((result) => {
          this.showaddTagsPanel = false;
          if (result?.data === 'No new tag-entity was added') {
            noNewEntityTag = true;
          }
        })
        .catch(() => {
          error = true;
        })
        .finally(() => {
          this.load = false;
          this.tagsLoader.load = false;
          const snackbarObject = {
            message: 'Something went wrong!',
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ''
          };
          if (noNewEntityTag) {
            snackbarObject.message =
              'Campaign is already a part of the campaign list';
          } else if (!error) {
            snackbarObject.message =
              'Campaign is added to Campaign List(s) successfully!';
            snackbarObject.actionText = 'Visit & Manage Campaign Lists';
            snackbarObject.onAction = () => {
              this.$router.push({ path: '/tools_and_settings/campaign-lists' });
            };
            this.$store
              .dispatch('fetchPostProcessingStatus', 'campaign')
              .then(() => {
                utils.checkAndClearLSFilterForEntity('campaign');
              });
          }
          this.tagState.add = [];
          this.$snackbar.open(snackbarObject);
        });
    },
    createTagsRequest(activeTags) {
      let create = true;
      const allTags = this.getAllTags?.rows?.map((item) => item.title);
      const entities = [
        {
          value: this.entityId,
          type: 'campaign',
          description: this.campaignName,
          image: ''
        }
      ];
      const tags = activeTags.map((item) => {
        if (allTags.includes(item?.title)) {
          create = false;
        }
        return {
          level1: item?.title?.trim(),
          level2: '',
          level3: '',
          level4: '',
          level5: '',
          type: 'custom',
          desc: '',
          customType: 'Custom'
        };
      });
      return {
        entityTaggingAddRequest: {
          tags,
          entities,
          create
        }
      };
    },
    fetchExistingTags() {
      const APIConfig = cloneDeep(this.APIConfig);
      deepReplaceObjectValues(APIConfig, ':entityId', this.entityId);
      this.$store.dispatch('fetchAllEntitiesWithAllTags', {
        APIConfig,
        localFilters: [],
        useExistingWhereClause: true
      });
    }
  }
};
</script>
<style lang="css">
.campaign-details .filter--token {
  border-radius: 2px;
  padding: 0;
}
.campaigns-tags-input .tag_token {
  padding: 4px 8px;
}
</style>
